/* Footer */
.footer {
	margin-top: auto;
	padding: 25px 0;
	background-color: var(--footer-bg);
	color: var(--footer-text);
}

.footer__wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 27px;
}

.social {
	display: flex;
	column-gap: 30px;
	align-items: center;
}

.social__item {
	transition: transform 0.4s;
}

.social__item:hover,
.social__item:active {
	transform: scale(1.1);
}

.copyright {
	font-size: 16px;
}

.copyright p + p {
	margin-top: 0.5em;
}